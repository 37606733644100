<template>
  <div class="layout__wrapper">
    <slot name="page" />
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  data() {
    return {
    };
  },
  mounted() {
  },
  components: {
  },
};
</script>
